import { useUserSession } from './useUserSession'
import { useCountriesData } from '@utils/useCountriesData'
import { erpSystemTypes } from '@src/routes'

// Return true/false if it's China site user.
export const useChinaUser = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'CN'
}

export const useKoreaUser = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'KR'
}

export const useMexicoUser = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'MX'
}

export const useIndonesiaUser = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'ID'
}

export const useJapanUser = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'JP'
}

export const useUsUer = (): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === 'US'
}

export const useTaxExemptionEligibleUser = (): boolean => {
  const { userSession } = useUserSession()
  return (
    userSession.country.toLocaleUpperCase() === 'US' ||
    userSession.country.toLocaleUpperCase() === 'CA'
  )
}

export const useInvoiceEmailEligibleUser = (): boolean => {
  const { userSession } = useUserSession()

  const eligibleCountries = [
    'SE',
    'DK',
    'NO',
    'FI',
    'UK',
    'BE',
    'NL',
    'LU',
    'IE',
    'DE',
    'CH',
    'AT',
    'FR',
    'IT',
    'ES',
    'PT',
  ]

  const userCountry = userSession.country.toLocaleUpperCase()

  return eligibleCountries.includes(userCountry)
}

export const useCountryUser = (countryCode: string): boolean => {
  const { userSession } = useUserSession()
  return userSession.country.toLocaleUpperCase() === countryCode
}

export const useIsIscalaCountry = (): boolean => {
  const { userSession } = useUserSession()
  const { getCountryDetails } = useCountriesData()

  const userCountryInfo = getCountryDetails(
    userSession.country.toLocaleUpperCase()
  )
  return userCountryInfo.erpSystemId === erpSystemTypes.Iscala
}

export const useIsQrpCountry = (): boolean => {
  const { userSession } = useUserSession()
  const { getCountryDetails } = useCountriesData()

  const userCountryInfo = getCountryDetails(
    userSession.country.toLocaleUpperCase()
  )
  return userCountryInfo.erpSystemId === erpSystemTypes.Qrp
}
