import React from 'react'
import {
  OptimizelyProvider,
  createInstance,
  enums,
} from '@optimizely/react-sdk'
import { useCookies } from '@src/utils/cookies'
import { useUserSession } from '@utils/useUserSession'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { isServer } from '@src/utils/isServer'
import { sendEvent } from '@utils/analytics/coreAnalytics'
import { GTMEventTypes } from '@sial/common-utils'
import { useRouter } from '@src/routes'

const SIALOptimizelyProvider: React.FC<{
  datafile: any
  children: React.ReactNode
}> = ({ datafile, children }) => {
  const [cookies] = useCookies(['GUID'])
  const { userIsLoggedIn } = useCurrentUser({})
  const { userSession } = useUserSession()
  const optimizelyInstance = createInstance({
    datafile,
  })
  const guidCookie = cookies['GUID'] || 'default_user'
  const router = useRouter()

  if (!datafile) return <>{children}</>

  /**
   * UIPT-406 - Optly Feature Flags Connection to GA4
   * https://docs.developers.optimizely.com/feature-experimentation/docs/set-up-google-analytics-4-ga4
   */
  const onDecision = ({ type, decisionInfo }) => {
    // Add a DECISION Notification Listener for type FLAG
    if (type === 'flag') {
      sendEvent({
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: {
          event: 'optimizely-decision-fs',
          'optimizely-flagKey': decisionInfo.flagKey,
          'optimizely-ruleKey': decisionInfo.ruleKey,
          'optimizely-variationKey': decisionInfo.variationKey,
        },
      })
    }
  }

  optimizelyInstance?.notificationCenter?.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    // @ts-ignore
    onDecision
  )

  return (
    <OptimizelyProvider
      isServerSide={isServer()}
      optimizely={optimizelyInstance}
      user={{
        id: guidCookie,
        attributes: {
          userIsLoggedIn: userIsLoggedIn,
          locale: `${userSession.language}_${userSession.country}`,
          country: userSession.country,
          language: userSession.language,
          route: router.asPath,
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}

export default SIALOptimizelyProvider
